import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from "../../Config/Config";
import { serverTimestamp, addDoc, getDoc, collection, updateDoc, doc, arrayUnion } from "firebase/firestore";
import '../FoodMenu/style.css';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';


export default function MyCart() {
    const [cart, setCart] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [globalData, setGlobalData] = useState({});
    const [shopName, setShopName] = useState('');
    const [orderId, setOrderId] = useState('');
    const [showOrderModal, setShowOrderModal] = useState(false);

    const navigate = useNavigate();
    const scrollRef = useRef();

    const [user, setUser] = useState({
        FirstName: '',
        LastName: '',
        Address: '',
        Phone: '',
    });
    const [data, setData] = useState({ Address: '', Phone: '', Instruction: '', OrderType: '', Campus: '' });

    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        setData({ ...data, [id]: value });
    };

    const handleOrderType = (event) => {
        const selectedServices = event.target.value;
        setData({ ...data, OrderType: selectedServices });
    };

    useEffect(() => {
        const unsubscribeAuthStateChange = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const uid = user.uid;
                const userRef = doc(db, 'users', uid);
                const globalRef = doc(db, 'globals', 'app');
                try {
                    const docSnapshot = await getDoc(userRef);
                    const globalSnapshot = await getDoc(globalRef);
                    if (globalSnapshot.exists()) {
                        const globalsData = globalSnapshot.data();
                        setGlobalData(globalsData);
                    }
                    if (docSnapshot.exists()) {
                        const userData = docSnapshot.data();
                        setUser(userData);
                        setData({ Address: userData.Address, Phone: userData.Phone, Campus: userData.Campus });
                    }
                } catch (error) {
                    toast.error(error.message);
                }
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, []);

    let fullName;
    if (user) {
        const firstName = user.FirstName.charAt(0).toUpperCase() + user.FirstName.slice(1).toLowerCase();
        const lastName = user.LastName.charAt(0).toUpperCase() + user.LastName.slice(1).toLowerCase();
        fullName = firstName + ' ' + lastName;
    }


    const handleCartUpdate = (updatedCart) => {
        setIsLoading(true);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    };

    const addToCart = (shopId, productId) => {
        const updatedCart = { ...cart };
        updatedCart[shopId][productId] += 1;
        handleCartUpdate(updatedCart);
    };

    const removeFromCart = (shopId, productId, outOfStock) => {
        const updatedCart = { ...cart };
        if (outOfStock) {
            if (updatedCart[shopId] && updatedCart[shopId][productId] > 1) {
                delete updatedCart[shopId][productId];
            } else {
                delete updatedCart[shopId][productId];
                if (Object.keys(updatedCart[shopId]).length === 0) {
                    delete updatedCart[shopId];
                }
            }
        } else {
            if (updatedCart[shopId] && updatedCart[shopId][productId] > 1) {
                updatedCart[shopId][productId] -= 1;
            } else {
                delete updatedCart[shopId][productId];
                if (Object.keys(updatedCart[shopId]).length === 0) {
                    delete updatedCart[shopId];
                }
            }
        }
        handleCartUpdate(updatedCart);
    };

    const calculateCharge = (totalAmount, type) => {

        let charge = totalAmount * globalData[`feePercent${type}`];
        return parseFloat(charge.toFixed(2));
    };

    const subTotalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    let deliveryFee = 0;
    deliveryFee = calculateCharge(subTotalAmount, 'Convenience').toFixed(2);

    const platformFee = calculateCharge(subTotalAmount, 'Platform');

    let packingFee = 0;
    if (data.OrderType === 'Dine-In') {
        packingFee = 0;
    } else {
        packingFee = calculateCharge(subTotalAmount, 'Packing');
    }

    const totalAmount = parseFloat(subTotalAmount) + parseFloat(deliveryFee) + platformFee + packingFee;
    const payAmountDelivery = deliveryFee; // same as Convenience

    useEffect(() => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []);

    useEffect(() => {
        const fetchCartItems = async () => {
            setIsLoading(true);
            const cartItemsArray = [];

            for (const shopId in cart) {
                for (const productId in cart[shopId]) {
                    const quantity = cart[shopId][productId];

                    try {
                        const productData = await db.collection('businessUsers').doc(shopId).get();
                        const shop = productData.data();
                        const productDetails = shop.products.find((product) => product.Id === productId);

                        if (productDetails) {
                            cartItemsArray.push({
                                shopId,
                                productId,
                                name: productDetails.Name,
                                price: productDetails.Price,
                                category: productDetails.Category,
                                dietaryPreference: productDetails.DietaryPreference,
                                status: productDetails.status,
                                quantity,
                            });
                        }
                    } catch (error) {
                        toast.error('Error fetching product details. Please try after sometime!!');
                    }
                }
            }
            setCartItems(cartItemsArray);
            setIsLoading(false);
        };
        fetchCartItems();
    }, [cart]);

    const fetchOrderId = async () => {
        setIsLoading(true);
        const url = globalData.live ? globalData.apiLinkLive : globalData.apiLinkTest;
        const paisa = Math.round(totalAmount * 100);
        try {
            const response = await fetch(`${url}${paisa}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({}),
            });

            if (response.ok) {
                const data = await response.json();
                setOrderId(data.orderID);
            } else {
                setOrderId('');
            }
        } catch (error) {
            setOrderId('');
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddOrder = () => {
        toast.info('Online ordering is only supported on the mobile app!!');
    };

    // const handleAddOrder = async (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);

    //     const currentUser = auth.currentUser;

    //     if (currentUser && data.OrderType && data.Phone && (data.OrderType !== 'Delivery' || data.Address)) {
    //         try {
    //             await fetchOrderId();
    //             const shopId = cartItems.length > 0 ? cartItems[0].shopId : null;
    //             const shopDoc = await db.collection('businessUsers').doc(shopId).get();

    //             if (shopDoc.exists) {
    //                 const shopStatus = shopDoc.data().ShopStatus;
    //                 const shopName = shopDoc.data().BusinessName;
    //                 setShopName(shopName);

    //                 if (shopStatus === 'Open') {
    //                     var options = {
    //                         key: globalData.live ? globalData.apiKeyLive : globalData.apiKeyTest,
    //                         key_secret: globalData.live ? globalData.apiSecretLive : globalData.apiSecretTest,
    //                         amount: Math.round(totalAmount * 100),
    //                         currency: "INR",
    //                         order_id: orderId,
    //                         order_receipt: 'order_rcptid_',
    //                         name: "Cravee",
    //                         handler: async function (response) {
    //                             if (response.razorpay_payment_id) {
    //                                 await addOrderToDatabase(response.razorpay_payment_id, response.razorpay_order_id, shopName);
    //                             } else {
    //                                 toast.error(`Payment failed: ${response.error.description || 'Unknown error'}`);
    //                             }
    //                         },
    //                         theme: {
    //                             color: "#3399cc"
    //                         }
    //                     };

    //                     var pay = new window.Razorpay(options);
    //                     pay.open();
    //                 } else {
    //                     toast.info('The shop is currently not accepting orders. Please try again later.');
    //                 }
    //             } else {
    //                 toast.error('Error fetching shop details. Please try again later.');
    //             }
    //         } catch (error) {
    //             toast.error(`Error placing order: ${error.message || 'Unknown error'}`);
    //         }
    //     } else {
    //         toast.info('Please fill in all required fields.');
    //     }
    //     setIsLoading(false);
    // };

    const addOrderToDatabase = async (paymentId, orderId, shopName) => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            const uid = currentUser.uid;
            const orderCollectionRef = collection(db, 'Orders');
            const orderInstruction = data.Instruction || '';

            try {
                const newOrder = {
                    userId: uid,
                    status: '',
                    products: cartItems.map(item => ({
                        productId: item.productId,
                        name: item.name,
                        category: item.category,
                        price: item.price,
                        dietaryPreference: item.dietaryPreference,
                        status: item.status,
                        quantity: item.quantity,
                    })),
                    userPhone: data.Phone,
                    OrderType: data.OrderType,
                    timestamp: serverTimestamp(),
                    shopId: cartItems[0].shopId,
                    shopName: shopName,
                    userName: fullName,
                    paymentId: paymentId,
                    orderId: orderId,
                    orderInstruction: orderInstruction,
                    itemSubtotal: parseInt(subTotalAmount),
                    totalAmount: totalAmount.toString(),
                };

                if (data.OrderType === 'Delivery') {
                    newOrder.userAddress = data.Address;
                    newOrder.deliveryCharge = deliveryFee.toString();
                    newOrder.userCampus = data.Campus;
                } else {
                    newOrder.convenienceCharge = deliveryFee.toString();
                }

                const orderDocRef = await addDoc(orderCollectionRef, newOrder);

                const userDocRef = doc(db, 'users', uid);
                await updateDoc(userDocRef, {
                    ordersID: arrayUnion(orderDocRef.id)
                });

                const businessUserDocRef = doc(db, 'businessUsers', cartItems[0].shopId);
                await updateDoc(businessUserDocRef, {
                    ordersID: arrayUnion(orderDocRef.id)
                });

                toast.success('Order placed successfully.');
                scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    localStorage.removeItem('cart');
                    navigate('/order');
                }, 1500);
            } catch (error) {
                toast.error("Error placing order. Please try again later.");
            }
        }
    };

    const outOfStockProduct = cartItems.find(item => item.status === 'Out of Stock');

    return (
        <>
            <div ref={scrollRef}></div>
            <section className="h-100" style={{ padding: '0px' }}>
                {isLoading && <Loader />}
                <div className="container h-100 py-5">
                    <ToastContainer
                        position="top-right"
                        autoClose={2400}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12">
                            {outOfStockProduct && (
                                <div className="alert alert-warning mx-3" role="alert">
                                    <h5>One of the products in your cart ({outOfStockProduct.name}) is out of stock.</h5>
                                    <button className="btn btn-primary btn-xs" onClick={() => removeFromCart(outOfStockProduct.shopId, outOfStockProduct.productId, true)}>
                                        Remove it from the cart
                                    </button>
                                </div>
                            )}

                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h2 className="fw-normal mb-0 text-black">Shopping Cart</h2>
                            </div>
                            {cartItems.map((item, index) => (
                                <div className="card rounded-3 mb-4" key={index} style={{ padding: '0 60px 0 20px' }}>
                                    <div className="card-body p-4">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="col-md-3 col-lg-3 col-xl-3">
                                                <div className="dietary-preference">
                                                    {item.dietaryPreference === 'Veg' && (
                                                        <i className="fa-solid fa-circle" style={{ color: 'green' }} title="Veg"></i>
                                                    )}
                                                    {item.dietaryPreference === 'Non Veg' && (
                                                        <i className="fa-regular fa-square-caret-up" style={{ color: 'red' }} title="Non Veg"></i>
                                                    )}
                                                    {item.dietaryPreference === 'Egg' && (
                                                        <i className="fa-solid fa-egg" style={{ color: '#C7BC3C' }} title="Egg"></i>
                                                    )}
                                                </div>
                                                <p className="lead fw-normal mb-2">{item.name}</p>
                                                <p><span className="text-muted">Category: </span>{item.category}</p>
                                            </div>
                                            <div className="col-md-3 col-lg-3 col-xl-2">
                                                <p>Quantity: {item.quantity}</p>
                                            </div>
                                            <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                                                <h5 className="mb-0">&#8377; {item.price * item.quantity}</h5>
                                            </div>
                                            <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                                <button className="btn btn-link text-success" onClick={() => addToCart(item.shopId, item.productId)}>
                                                    <i className="fa-solid fa-square-plus" style={{ fontSize: "28px" }}></i>
                                                </button>
                                            </div>
                                            <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                                <button className="btn btn-link text-danger" onClick={() => removeFromCart(item.shopId, item.productId, false)}>
                                                    <i className="fa-solid fa-square-minus" style={{ fontSize: "28px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <div className="float-end">
                                        <p className="mb-0 me-5 d-flex align-items-center">
                                            <span className="small text-muted me-2">Order Subtotal:</span> <span className="lead fw-normal">&#8377; {subTotalAmount}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row py-1 p-4 bg-white rounded shadow-sm">
                                <div className="col-lg-6">
                                    <div className="bg-light rounded-pill px-4 py-3 mt-5 text-uppercase font-weight-bold">Order Details</div>
                                    <div className="p-4">
                                        <div className="row mt-3 mx-3" style={{ marginTop: "25px" }}>
                                            <div className="col-md-12 justify-content-center">
                                                <div className="row mb-4">
                                                    <div className="col">
                                                        <FormControl fullWidth>
                                                            <InputLabel id="OrderTypeLabel">Order Type<span className="text-danger"> *</span></InputLabel>
                                                            <Select
                                                                labelId="OrderTypeLabel"
                                                                id="OrderType"
                                                                value={data.OrderType}
                                                                label="Order Type"
                                                                onChange={handleOrderType}
                                                                required
                                                            >
                                                                <MenuItem value="" disabled>Select Order Type</MenuItem>
                                                                <MenuItem value={'Delivery'}>Delivery</MenuItem>
                                                                <MenuItem value={'Takeaway'}>Takeaway</MenuItem>
                                                                <MenuItem value={'Dine-In'}>Dine-In</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <form className="mb-4">
                                                    <div className="row mb-8">
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <input type="text" id="FullName" className="form-control input-custom" value={fullName} readOnly />
                                                                <label className="form-label" htmlFor="FullName">Full name</label>
                                                                <span className="text-danger"> *</span>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-outline">
                                                                <input name="phone" type="text" id="Phone" className="form-control input-custom" value={data.Phone} onChange={handleChange} />
                                                                <label className="form-label" htmlFor="Phone">Phone</label>
                                                                <span className="text-danger"> *</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {data.OrderType === 'Delivery' && (
                                                        <div className="row mb-4">
                                                            <div className="col">
                                                                <div className="form-outline">
                                                                    <input
                                                                        type="text"
                                                                        id="Address"
                                                                        className="form-control input-custom"
                                                                        value={data.Address}
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                    <label className="form-label" htmlFor="Address">
                                                                        Address (Include College Name)
                                                                    </label>
                                                                    <span className="text-danger"> *</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-light rounded-pill px-4 py-3 text-uppercase font-weight-bold">Instructions for seller</div>
                                    <div className="p-4">
                                        <p className="font-italic mb-4">If you have some information for the seller you can leave them in the box below</p>
                                        <textarea id="Instruction" name="Instruction" cols="30" rows="2" className="form-control" value={data.Instruction} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="bg-light rounded-pill px-4 py-3 mb-3 text-uppercase font-weight-bold" style={{ marginTop: '-30px' }}>Order summary{" "}</div>
                                    <div className="p-4">
                                        <p className="font-italic mb-4">Shipping and additional costs are calculated based on values you have entered.</p>
                                        <ul className="list-unstyled mb-4">
                                            <li className="d-flex justify-content-between py-3 border-bottom"><strong className="text-muted">Order Subtotal </strong><strong>&#8377; {subTotalAmount}</strong></li>
                                            {totalAmount === '0.00' ? (
                                                data.OrderType === 'Delivery' ? (
                                                    <li className="d-flex justify-content-between py-3 border-bottom">
                                                        <strong className="text-muted">Shipping and handling</strong>
                                                        <strong>&#8377;0.00</strong>
                                                    </li>
                                                ) : (
                                                    <li className="d-flex justify-content-between py-3 border-bottom">
                                                        <strong className="text-muted">Convenience Fee</strong>
                                                        <strong>&#8377;0.00</strong>
                                                    </li>
                                                )
                                            ) : (
                                                data.OrderType === 'Delivery' ? (
                                                    <li className="d-flex justify-content-between py-3 border-bottom">
                                                        <strong className="text-muted">Shipping and handling</strong>
                                                        <strong>&#8377;{deliveryFee}</strong>
                                                    </li>
                                                ) : (
                                                    <li className="d-flex justify-content-between py-3 border-bottom">
                                                        <strong className="text-muted">Convenience Fee</strong>
                                                        <strong>&#8377;{deliveryFee}</strong>
                                                    </li>
                                                )
                                            )
                                            }
                                            <li className="d-flex justify-content-between py-3 border-bottom"><strong className="text-muted">Tax</strong><strong>&#8377;0.00</strong></li>
                                            <li className="d-flex justify-content-between py-3 border-bottom"><strong className="text-muted">Total</strong>
                                                <h5 className="font-weight-bold">&#8377;{totalAmount.toFixed(2)}</h5>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-success btn-lg me-2">
                                            <Link to={cartItems.length > 0 ? `/${cartItems[0].shopId}` : '/'} className="text-decoration-none text-white">
                                                Continue Shopping
                                            </Link>
                                        </button>
                                        <button type="button" className="btn btn-primary btn-lg" onClick={handleAddOrder} disabled={outOfStockProduct !== undefined}>
                                            Proceed To Pay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
}
