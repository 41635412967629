import firebase from 'firebase/compat/app';
import { GoogleAuthProvider } from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCTGsemWL5913z75weLfnBzRQjo7whf9vY",
    authDomain: "kbseva-ebf84.firebaseapp.com",
    projectId: "kbseva-ebf84",
    storageBucket: "kbseva-ebf84.appspot.com",
    messagingSenderId: "636087547252",
    appId: "1:636087547252:web:433e2e397575c38f0c2821",
    measurementId: "G-T6ES43LW2P"
};

const initialize = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const provider = new GoogleAuthProvider();

export { initialize, auth, db, storage, provider }