import React, { useEffect, useState } from 'react';
import './style.css';
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { getDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function DataTable() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('');

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const updateProductStatus = async (productId, status) => {
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const uid = currentUser.uid;
                const userRef = doc(db, 'businessUsers', uid);
                const docSnapshot = await getDoc(userRef);
                const userData = docSnapshot.data();

                const products = userData.products || [];

                const updatedProducts = products.map((product) => {
                    if (product.Id === productId) {
                        return { ...product, status };
                    }
                    return product;
                });

                await updateDoc(userRef, { products: updatedProducts });
            }
        } catch (error) {
            toast.error(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async (uid) => {
            setIsLoading(true);
            try {
                const userRef = doc(db, 'businessUsers', uid);
                const unsub = onSnapshot(
                    userRef,
                    (docSnapshot) => {
                        if (docSnapshot.exists()) {
                            const userData = docSnapshot.data();
                            const products = userData.products || [];
                            setData([{ id: docSnapshot.id, products }]);
                        } else {
                            setData([]);
                        }
                        setIsLoading(false);
                    },
                    (error) => {
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                );
                return () => {
                    unsub();
                };
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false);
            }
        };

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchData(user.uid);
            } else {
                setData([]);
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleDelete = async (id) => {
        setIsLoading(true);
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const uid = currentUser.uid;
                const userRef = doc(db, 'businessUsers', uid);
                const docSnapshot = await getDoc(userRef);
                const userData = docSnapshot.data();

                const products = userData.products || [];

                const updatedProducts = products.filter((product) => product.Id !== id);
                await updateDoc(userRef, { products: updatedProducts });

                toast.success(
                    'Successfully Deleted the Product!!'
                );

                setData((prevState) => {
                    return prevState.map((user) => {
                        if (user.id === uid) {
                            return { ...user, products: updatedProducts };
                        }
                        return user;
                    });
                });
            }
        } catch (err) {
            toast.error(err.message);
        }
        setIsLoading(false);
    };

    const columns = [
        {
            field: 'Id',
            headerName: 'Product ID',
            width: 130,
            renderCell: (params) => {
                const productId = params.value;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#1976D2' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#bootstrapModal${productId}`}
                    >
                        {productId}
                    </div>
                );
            },
        },

        {
            field: "Name",
            headerName: "Product Name",
            width: 200,
            renderCell: (params) => {
                const productName = params.value;
                const productSubtitle = params.row.subtitle;

                return (
                    <div>
                        <div style={{ fontWeight: 'bold', color: '#777' }}>{productName}</div>
                        {productSubtitle && (
                            <div>{productSubtitle}</div>
                        )}
                    </div>
                );
            },
        },


        {
            field: "DietaryPreference",
            headerName: "Dietary Pref",
            width: 120,
        },

        {
            field: "Category",
            headerName: "Category",
            width: 150,
        },

        {
            field: "Price",
            headerName: "Price",
            width: 85,
        },

        {
            field: "productQR",
            headerName: "Product QR",
            width: 95,
        },

        {
            field: 'status',
            headerName: 'Status',
            width: 170,
            renderCell: (params) => {
                const productStatus = params.value || null;
                return (
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>Select Status</InputLabel>
                        <Select
                            value={productStatus}
                            onChange={(event) => {
                                handleStatusChange(event);
                                updateProductStatus(params.row.id, event.target.value);
                            }}
                            label="Status"
                        >
                            <MenuItem value={null} disabled>Select Status</MenuItem>
                            <MenuItem value="Available">Available</MenuItem>
                            <MenuItem value="Out of Stock">Out of Stock</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },

        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to={`/business/product/modify/${params.row.Id}`} style={{ textDecoration: "none" }}>
                            <div className="viewButton effect effect-2 mt-1">Modify</div>
                        </Link>
                        <div
                            className="deleteButton effect effect-2 mb-1"
                            onClick={() => handleDelete(params.row.Id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        }
    ];

    const flattenedData = data.flatMap((entry) => {
        return entry.products.map((product, index) => {
            return {
                ...product,
                id: product.Id,
            };
        });
    });

    return (
        <>
            {data.length > 0 && data[0].products && data[0].products.map((product) => (
                <div className="modal fade" id={`bootstrapModal${product.Id}`} tabIndex="-1" aria-labelledby={`bootstrapModalLabel${product.Id}`} aria-hidden="true" key={`bootstrap${product.Id}`}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start text-black p-3">
                                <h5 className="modal-title text-uppercase text-center mb-4" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>Product Summary</h5>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Product ID</p>
                                        <p>{product.Id}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Product Name</p>
                                        <p>{product.Name}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Price</p>
                                        <p>&#8377; {product.Price}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Product Category</p>
                                        <p>{product.Category}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Dietary Preferencece</p>
                                        <p>{product.DietaryPreference}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Product Status</p>
                                        <p>{product.status}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    {product.subtitle ? (
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Subtitle</p>
                                            <p>{product.subtitle}</p>
                                        </div>
                                    ) : null}
                                    {product.packingFee ? (
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Packing Charge</p>
                                            <p>&#8377; {product.packingFee}</p>
                                        </div>
                                    ) : null}
                                </div>

                                {product.Customization && product.Customization.length > 0 && (
                                    <h4 className="mt-2 mb-2" style={{ color: "#35558a" }}>Customization Summary</h4>
                                )}

                                <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                <div>
                                    {product.Customization && product.Customization.some(item => item.customizationType === 'Size') && (
                                        <>
                                            <h5 className="mb-2" style={{ color: "#35558a" }}>Size Availables</h5>
                                            {product.Customization
                                                .filter(item => item.customizationType === 'Size' && item.customizationName)
                                                .map(item => (
                                                    <div className="d-flex justify-content-between" key={item.customizationName}>
                                                        <p className="mb-0">{item.customizationName}</p>
                                                        <p className="mb-0">+ &#8377;{item.customizationPrice}</p>
                                                    </div>
                                                ))}
                                        </>
                                    )}

                                    {product.Customization && product.Customization.some(item => item.customizationType === 'Crust') && (
                                        <>
                                            <h5 className="mt-2 mb-2" style={{ color: "#35558a" }}>Choice of Crust</h5>
                                            {product.Customization
                                                .filter(item => item.customizationType === 'Crust' && item.customizationName)
                                                .map(item => (
                                                    <div className="d-flex justify-content-between" key={item.customizationName}>
                                                        <p className="mb-0">{item.customizationName}</p>
                                                        <p className="mb-0">+ &#8377;{item.customizationPrice}</p>
                                                    </div>
                                                ))}
                                        </>
                                    )}

                                    {product.Customization && product.Customization.some(item => item.customizationType === 'Toppings') && (
                                        <>
                                            <h5 className="mt-2 mb-2" style={{ color: "#35558a" }}>Toppings</h5>
                                            {product.Customization
                                                .filter(item => item.customizationType === 'Toppings' && item.customizationName)
                                                .map(item => (
                                                    <div className="d-flex justify-content-between" key={item.customizationName}>
                                                        <p className="mb-0">{item.customizationName}</p>
                                                        <p className="mb-0">+ &#8377;{item.customizationPrice}</p>
                                                    </div>
                                                ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="datatable">
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                {isLoading && <Loader />}
                <div className="datatableTitle">
                    Products
                    <Link to="/business/product/add" className="link">
                        Add New
                    </Link>
                </div>
                <DataGrid
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    className="datagrid"
                    rows={flattenedData}
                    columns={columns}
                    getRowHeight={({ id, densityFactor }) => {
                        return 60 * densityFactor;
                    }}
                />
            </div>
        </>
    );
}
